.card-link {
  text-decoration: none;
  color: #E6005C;
}

.card-link:hover {
  background-color: rgba(230, 0, 92, 0.1);
  color: #E6005C;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.navbar {
  box-shadow: 0 2px 10px rgba(230, 0, 92, 0.1);
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.9) !important;
}

.industry-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(230, 0, 92, 0.1);
  transition: transform 0.2s ease-in-out;
  margin-bottom: 2rem;
}

.industry-card:hover {
  transform: translateY(-5px);
}

.industry-title {
  color: #333;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.industry-icon {
  color: #E6005C;
  opacity: 0.8;
}

.page-link {
  padding: 1rem;
  border-radius: 12px;
  background: #f8f9fa;
  margin: 0.5rem 0;
  transition: all 0.2s ease;
}

.page-link:hover {
  background: rgba(230, 0, 92, 0.05);
  transform: translateX(5px);
}

body {
  background: linear-gradient(135deg, #fff5f8 0%, #fff 100%);
  min-height: 100vh;
}

.navbar-brand {
  font-weight: 700;
  letter-spacing: -0.5px;
}

.nav-link {
  font-weight: 500;
  color: #333 !important;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #E6005C !important;
}

.industry-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(120deg, #E6005C, #ff4d94);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  letter-spacing: -0.03em;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.industry-title::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #E6005C20, transparent);
  z-index: -1;
}

.industry-card {
  position: relative;
  overflow: hidden;
}

.corner-label {
  position: absolute;
  top: 15px;
  right: -45px;
  transform: rotate(45deg);
  padding: 5px 40px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
  z-index: 1;
  width: 150px;
}

.industry-icon {
  position: relative;
  z-index: 2;
}

.coming-soon {
  background-color: #E6005C;
  font-size: 10px;
  /* Smaller font just for Coming Soon */
}

.in-progress {
  background-color: #ff4d94;
  /* Bright pink */
}

.coming-soon {
  background-color: #E6005C;
  /* Deeper pink - matches QM brand color */
}